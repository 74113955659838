require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
import Splide from '@splidejs/splide'
import "@fortawesome/fontawesome-free/js/all";
import jquery from 'jquery';
import 'bootstrap';
import './custom'

global.Splide = Splide;
global.$ = jquery;

require("trix")
require("@rails/actiontext")
