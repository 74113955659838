import AOS from 'aos/dist/aos';
import 'aos/dist/aos.css';

document.addEventListener('turbolinks:load', function () {
  AOS.init({
    once: true
  });
});

document.addEventListener('turbolinks:before-visit', () => {
  Turbolinks.clearCache();
})
